import(/* webpackMode: "eager" */ "/home/runner/work/squadre/squadre/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/squadre/squadre/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/squadre/squadre/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/squadre/squadre/node_modules/@olinfo/react-components/dist/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/squadre/squadre/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/squadre/squadre/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/squadre/squadre/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/squadre/squadre/src/app/logo-dark.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/squadre/squadre/src/app/logo-light.svg");
